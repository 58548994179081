// © Microsoft Corporation. All rights reserved.

import {
    environment,
    initializeMessageHandler,
    loadConfigTree,
    perfMarker,
    registerConfigResolverTasks,
    registerOneServiceFeedTasks,
    registerOneServiceAuthHeaderListener
} from "@msnews/web-worker";

(() => {
    // since we can't use perfMarker until after environment is initialized,
    // capture the raw time offset at start now, so we can use it after init.
    const startTime = performance.now();

    // initialize environment variables
    environment.init();

    // record with the custom time we saved earlier once environment has been fully initialized.
    perfMarker("Start", startTime);

    // add message listener first to avoid missing messages from main thread
    initializeMessageHandler();

    // Register auth header listener from main thread so that WW 1S calls can be personalized
    registerOneServiceAuthHeaderListener();

    // register tasks dependent on/should wait for other tasks here
    registerConfigResolverTasks();
    registerOneServiceFeedTasks();

    // start independent tasks here
    loadConfigTree();
})();